import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AuthService from "../../services/users/innerpage.service";

export const getInnerPage = createAsyncThunk(
  "userinnerpage/get",
  async (thunkAPI) => {
    try {
      const data = await AuthService.getInnerPages();
      return data.docs;
    } catch (error) {
      console.log(error, "error during get nav items user info");
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInnerPageExcludeContent = createAsyncThunk(
  "userinnerpage/getExcludeContent",
  async (thunkAPI) => {
    try {
      const data = await AuthService.getInnerPagesExcludeContent();
      console.log("inner pages",data)
      return data.docs;
    } catch (error) {
      console.log(error, "error during get nav items user info");
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInnerPageDetail = createAsyncThunk(
  "innerpages/getDetail",
  async (slugOrId, thunkAPI) => {
    try {
      const data = await AuthService.getInnerPageDetail(slugOrId);
      return data;
    } catch (error) {
      console.log(error, "error during get nav items user info");
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getInnerPageByCategory = createAsyncThunk(
  "innerpages/getByCategory",
  async (category, thunkAPI) => {
    try {
      const data = await AuthService.getInnerPageByCategory(category);
      return data.docs;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getArticleBreadcrumb = createAsyncThunk(
  "innerpages/getArticleBreadcrumb",
  async (slugOrId, thunkAPI) => {
    try {
      const data = await AuthService.getArticleBreadcrumb(slugOrId);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = { isLoader: false, innerPageInfo: null, innerPageInfoSection:null, breadcrumb:null };

const innerPageSlice = createSlice({
  name: "userinnerpage",
  initialState,
  extraReducers: {
    [getInnerPage.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerPageInfo = action.payload;
    },
    [getInnerPage.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerPageInfo = null;
    },
    [getInnerPageDetail.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerPageDetail = action.payload;
      console.log("State inner page detail", state.innerPageDetail)
    },
    [getInnerPageDetail.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerPageDetail = null;
    },
    [getInnerPageExcludeContent.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerPageInfo = action.payload;
    },
    [getInnerPageExcludeContent.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerPageInfo = null;
    },
    [getInnerPageByCategory.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.innerPageInfoSection = action.payload;
      state.innerPageInfo = action.payload; // Add this line
    },
    [getInnerPageByCategory.rejected]: (state, action) => {
      state.isLoader = false;
      state.innerPageInfoSection = null;
    },
    [getArticleBreadcrumb.fulfilled]: (state, action) => {
      state.isLoader = false;
      state.breadcrumb = action.payload;
    },
    [getArticleBreadcrumb.rejected]: (state, action) => {
      state.isLoader = false;
      state.breadcrumb = null;
    },
  },
});

const { reducer } = innerPageSlice;
export default reducer;
